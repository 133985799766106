import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt_decode from 'jwt-decode'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/resend',
    name: 'Resend',
    component: () => import('@/views/pages/Resend.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/verifikasi',
    name: 'Verifikasi',
    component: () => import('@/views/pages/Verifikasi.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/layanan-pengaduan',
    name: 'LayananPengaduan',
    component: () => import('@/views/pages/LayananPengaduan.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  //ADMIN
  {
    path: '/admin/halaman',
    name: 'AdminHalaman',
    component: () => import('@/views/admin/AdminHalaman.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/pelatihan',
    name: 'AdminPelatihan',
    component: () => import('@/views/admin/AdminPelatihan.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/pelatihan/detail/:id',
    name: 'AdminPelatihanDetail',
    component: () => import('@/views/admin/AdminPelatihanDetail.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/user',
    name: 'User',
    component: () => import('@/views/admin/User.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/pelatihan_tugas/:id_pelatihan/:id_materi/:hari_ke',
    name: 'AdminPelatihanTugas',
    component: () => import('@/views/admin/AdminPelatihanTugas.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/pelatihan_evaluasi/:id_pelatihan/:id_materi/:hari_ke',
    name: 'AdminPelatihanEvaluasi',
    component: () => import('@/views/admin/AdminPelatihanEvaluasi.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/pelatihan_evaluasi_penyelengara/:id_pelatihan',
    name: 'AdminPelatihanEvaluasiPenyelenggara',
    component: () => import('@/views/admin/AdminPelatihanEvaluasiPenyelenggara.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/master_pertanyaan',
    name: 'AdminMasterPertanyaan',
    component: () => import('@/views/admin/AdminMasterPertanyaan.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/pelatihan_pretest/:id_pelatihan',
    name: 'AdminPelatihanPreTest',
    component: () => import('@/views/admin/AdminPelatihanPreTest.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/pelatihan_posttest/:id_pelatihan',
    name: 'AdminPelatihanPostTest',
    component: () => import('@/views/admin/AdminPelatihanPostTest.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  //END ADMIN

  //PESERTA
  {
    path: '/peserta/pelatihan',
    name: 'PesertaPelatihan',
    component: () => import('@/views/peserta/PesertaPelatihan.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/peserta/statusPelatihan',
    name: 'PesertaStatusPelatihan',
    component: () => import('@/views/peserta/PesertaStatusPelatihan.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/peserta/pelatihan_daftar/:id',
    name: 'PesertaPelatihanDaftar',
    component: () => import('@/views/peserta/PesertaPelatihanDaftar.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/peserta/pelatihan_detail/:id',
    name: 'PesertaPelatihanDetail',
    component: () => import('@/views/peserta/PesertaPelatihanDetail.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/peserta/pelatihan_tugas/:id_pelatihan/:id_materi/:hari_ke',
    name: 'PesertaPelatihanTugas',
    component: () => import('@/views/peserta/PesertaPelatihanTugas.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/peserta/pelatihan_evaluasi/:id_pelatihan/:id_materi/:hari_ke',
    name: 'PesertaPelatihanEvaluasi',
    component: () => import('@/views/peserta/PesertaPelatihanEvaluasi.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/peserta/pelatihan_evaluasi_penyelenggara/:id_pelatihan',
    name: 'PesertaPelatihanEvaluasiPenyelenggara',
    component: () => import('@/views/peserta/PesertaPelatihanEvaluasiPenyelenggara.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/peserta/pelatihan_pretest/:id_pelatihan',
    name: 'PesertaPelatihanPreTest',
    component: () => import('@/views/peserta/PesertaPelatihanPreTest.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pretest/:id_pelatihan',
    name: 'PreTest',
    component: () => import('@/views/pages/PreTest.vue'),
    meta: {
      requiresAuth: true,
      layout: 'blank',

    },
  },
  {
    path: '/peserta/pelatihan_posttest/:id_pelatihan',
    name: 'PesertaPelatihanPostTest',
    component: () => import('@/views/peserta/PesertaPelatihanPostTest.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/posttest/:id_pelatihan',
    name: 'PostTest',
    component: () => import('@/views/pages/PostTest.vue'),
    meta: {
      requiresAuth: true,
      layout: 'blank',

    },
  },
  //END PESERTA

  // PEMATERI
  {
    path: '/pemateri/statusPelatihan',
    name: 'PemateriStatusPelatihan',
    component: () => import('@/views/pemateri/PemateriStatusPelatihan.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pemateri/pelatihan_detail/:id',
    name: 'PemateriPelatihanDetail',
    component: () => import('@/views/pemateri/PemateriPelatihanDetail.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pemateri/pelatihan_tugas/:id_pelatihan/:id_materi/:hari_ke',
    name: 'PemateriPelatihanTugas',
    component: () => import('@/views/pemateri/PemateriPelatihanTugas.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  //END PEMATERI
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (store.getters.loggedIn === false) {
    await store.dispatch('getToken')
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.token == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      })
    } else {
      const tokenDecoded = jwt_decode(store.state.token)
      const user = tokenDecoded.data
      if (to.matched.some(record => record.meta.userCanAccess)) {
        if (to.meta.userCanAccess.includes(user.role)) {
          next()
        } else {
          next({ name: 'dashboard' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (store.state.token == null) {
      next()
    } else {
      next({ name: 'dashboard' })
    }
  } else {
    next()
  }
})

export default router
